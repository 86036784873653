<template>
  <div class="home-qa">
    <UiHeading :h="'2'" :type="'E2'" :text="'よくある質問'" />
    <div class="cover">
      <UiFlowBox
        :type="'B'"
        :barNo="'Q'"
        :barText="'コロナ対策はしていますか'"
        :answer="'はい、入店時の検温と消毒、アクリル板の完備、スタッフのマスク着用をしています。'"
      />
      <UiFlowBox
        :type="'B'"
        :barNo="'Q'"
        :barText="'駐車場はありますか'"
        :answer="'提携している駐車場はございません。店舗周辺には多数の有料駐車場がございますので、そちらのご利用をお願いします。近隣の駐車場は、通常30分200円、22時以降になると60分あたり100円でお車を停めることができます。<br>タンニング30分以上のお客様には、日焼けサロンHotDog近くの1017パーキングの駐車証明をご持参いただければ、駐車30分料金をタンニング代金よりお値引きさせていただきます。'"
      />
      <div class="map-cover">
        <img
          alt="提携駐車場"
          src="@/assets/hotdog-top-map.jpg"
          class="teikei-map"
        />
      </div>
      <UiFlowBox
        :type="'B'"
        :barNo="'Q'"
        :barText="'日焼けのメリットは何ですか？'"
        :answer="'外見を健康的に見せ、着ているファッションもより引き立ち魅力的にも見せることができます。健康面では風邪をひきにくい、疲れがたまりにくい、ダイエット効果、皮膚アレルギーへの効果など様々です。精神面ではホルモンバランスが整えられ、精神疲労の回復、気持ちが前向きになったりリラクゼーション効果もあります。タンニングは若い人ばかりが利用していると考えられがちですが、利用者年齢は10代～50代まで幅広く、男女問わず美容や健康を目的に利用していたり、外見の美しさを求めて利用される方もいます。'"
      />
      <UiFlowBox
        :type="'B'"
        :barNo="'Q'"
        :barText="'タンニング（日焼け）中のすごし方は？'"
        :answer="'必ず目を閉じてご利用ください。また、ムラ焼けを防ぐために体を裏返す、たまった汗を拭くことをお勧めいたします。'"
      />
      <UiFlowBox
        :type="'B'"
        :barNo="'Q'"
        :barText="'日焼けによってシミやシワができますか？'"
        :answer="'太陽のように有害で強い光を浴び続けるとシミやシワの原因になりやすいと言われています。日焼けマシンは、健康を目的に作られており、光そのものを最先端技術でコントロールしているので安心してご利用できます。急激な日焼け、長時間の日焼け、お肌に対して強すぎる陽射しは皮膚に対しての負担が大きく、このような日焼けの仕方の場合はシミの原因になる可能性があります。シワの原因は皮膚の乾燥です。日焼け後は当然のこと、普段の日常から充分に保湿することが重要です。'"
      />
    </div>

    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3261.373273030202!2d136.9069453155868!3d35.17224798031608!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x600370d6f2351897%3A0xe33d0e5c83d92e13!2z5ZCN5Y-k5bGLIOaXpeeEvOOBkeOCteODreODs--8qO-9j--9lO-8pO-9j--9hw!5e0!3m2!1sja!2sjp!4v1665019809877!5m2!1sja!2sjp"
      width="100%"
      height="450"
      style="border: 0"
      allowfullscreen=""
      loading="lazy"
    ></iframe>
    <p>
      愛知県名古屋市東区東桜1-10-36 第44オーシャンビル
      6階（※セントラルパーク7A出口からすぐ）
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiFlowBox from "@/components/Ui/FlowBox.vue";
export default {
  name: "HomeReason",
  components: {
    UiHeading,
    UiFlowBox,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.home-qa {
  .cover {
    padding: 50px 0 100px;
    @media screen and (max-width: 767px) {
      padding-bottom: 30px;
    }
  }
}
.map-cover {
  display: flex;
  align-items: center;
  justify-content: center;
  .teikei-map {
    width: 35%;
    margin: 0 0 100px;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}
</style>