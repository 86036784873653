import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import {
  setTitle,
  setDescription
} from '@/mixins/index.js'

//ここからページ
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  
  {
    path: '/',
    name: 'Home',
    component: Home,
        // redirect: '/fl/',
        meta: {
          title: '日焼けサロンHotDog-名古屋市中区栄テレビ塔そば',
          // description: '日焼けサロンHotDogは、名古屋栄のテレビ塔そばにあり、平日は深夜2時、金曜・土曜は深夜3時まで営業しています。歴20年の老舗で効果的な日焼けができるマシンを取り揃えている日焼けスタジオです。オイルやタオル、シャワーなどなどすべて料金に込で、てぶらでお越しいただくことができます。'
          description: '日焼けサロンHotDogは、名古屋栄のテレビ塔そばにあり、深夜2時まで営業しています。歴20年の老舗で効果的な日焼けができるマシンを取り揃えている日焼けスタジオです。オイルやタオル、シャワーなどなどすべて料金に込で、てぶらでお越しいただくことができます。'
        }
  },
  // {
  //   path: '/*',
  //   name: 'Home',
  //   component: Home,
  //       // redirect: '/fl/',
  //       meta: {
  //         title: '日焼けサロンHotDog-名古屋市中区栄テレビ塔そば',
  //         description: '日焼けサロンHotDogは、名古屋栄のテレビ塔そばにあり、平日は深夜2時、金曜・土曜は深夜3時まで営業しています。歴20年の老舗で効果的な日焼けができるマシンを取り揃えている日焼けスタジオです。オイルやタオル、シャワーなどなどすべて料金に込で、てぶらでお越しいただくことができます。'
  //       }
  // },
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition || to.meta.savedPosition == true) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  setTitle(to.meta.title);
  setDescription(to.meta.description);
  store.dispatch("setRootUrl");

  if (to.path === '/') {
    next()
    // 引数無しでnextを呼び出すと通常通りの遷移が行われる
  } else {
    next('/')
  }

  // next();

  });

export default router
