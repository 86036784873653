<template>
  <div class="ui-table2">
    <div class="table-inner">
      <table>
        <tr>
          <th>時間</th>
          <td class="gray">通常会員価格
          </td>
          <td class="gray og sp">ブラック<br class="sp-only">会員価格</td>
        </tr>
        <tr v-for="table in tableList" :key="table.id">
          <th>
            <div>{{ table.th }}</div>
          </th>
          <td>
            <div v-html="table.td"></div>
          </td>
          <td class="og">
            <div v-html="table.td2"></div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UiTable2",
  components: {},
  props: {
    tableList: Array,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
table {
  color: white;
  width: 100%;
  tr{
    th,td{
      border: white solid 1px;
      text-align: center;
      padding: 10px 0;
      width: 30%;
      vertical-align: middle;
    }
    th{
      background-color: #5f5754;
    }
    .gray{
      background-color: #5f5754;
    }
    .og{
      font-size: 20px;
      color: var(--sub);
    }
    .sp{
      @media screen and (max-width: 767px) {
font-size: 18px;
line-height: 1.2em;
      }
    }
    
    }
}
</style>