<template>
  <div class="ui-table4">
    <div class="table-inner">
      <table>
        <tr v-for="table in tableList" :key="table.id">
          <th>
            <div>{{ table.th }}</div>
          </th>
          <td>
            <div v-html="table.td"></div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UiTable4",
  components: {},
  props: {
    tableList: Array,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>

table {
  width: 100%;
  border-collapse: collapse;
  tr {
    text-align: left;
    th,
    td {
      padding: 8px 0;
      padding-left: 5px;
      border-top: solid 1px var(--sub2);
      border-bottom: solid 1px var(--sub2);
      vertical-align: middle;

       @media screen and (max-width: 767px){
         display: block;
        //  border-top: none;
         border: none;
         padding-left: 0px;
       }

    }
    th {
      width: 23%;
      min-width: 128px;
      text-align: center;
       @media screen and (max-width: 767px){
         width: 100%;
         background-color: #dfdfdf;
         padding: 5px 0;
       }
       div{
         @media screen and (max-width: 767px){
           padding: 0 16px;
         }
       }
    }
    td{
       @media screen and (max-width: 767px){
                  border-top: none;
                  display: flex;
                  justify-content: center;
                  padding: 10px 0;
       }
      div {
        border-left: solid 1px var(--sub2);
        padding-left: 20px;
         @media screen and (max-width: 767px){
           width: 90%;
           border-left: none;
           padding-left: 0;
           padding-bottom: 5px;
          //  border-top: solid 1px var(--black);
         }
      }
    }
  }
  tr:last-child{
    border-bottom: solid 1px var(--black);
     @media screen and (max-width: 767px){
       border: none;
     }
  }

}
</style>