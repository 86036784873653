<template>
  <v-app>
    <Header />
    <v-main>
      <router-view class="body-top" />
      <ToTopButton :path="path" />
    </v-main>
    <Footer :path="path" />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import "reset-css";
import Header from "@/components/Layout/Header.vue";
import ToTopButton from "@/components/Ui/ToTopButton.vue";
import Footer from "@/components/Layout/Footer.vue";

export default {
  name: "App",
  data() {
    return {};
  },
  components: {
    Header,
    Footer,
    ToTopButton,
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    path() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap");

#app {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  color: #231815;
}

:root {
  --main: #231815;
  --sub: #ec6f00;
  --sub2: #5f5754;
}

html * {
  box-sizing: border-box !important;
}

img {
  // max-width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}

.wrap {
  width: 100vw; //画面幅に対して、基本100%
  max-width: 1100px; //でも、1150pxを超える画面幅でwidth: 100%では困るのでこの設定
  // width: 1150px;
  margin: 0 auto;
  line-height: 2.4em;

  @media screen and (max-width: 1100px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.wrap-pala {//palalax専用wrap
  width: 100vw; //画面幅に対して、基本100%
  max-width: 1100px; //でも、1150pxを超える画面幅でwidth: 100%では困るのでこの設定
  // width: 1150px;
  margin: 0 auto;
  line-height: 2.4em;
@media screen and (max-width: 1100px){
  width: 100%;
}
}

.body-top {
  margin-top: 70px;
  @media screen and (max-width: 767px) {
    margin-top: 60px;
  }
}

.sp-only {
  display: none;
  @media screen and (max-width: 767px) {
    display: initial !important;
  }
}

.pc-only {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

h1,
h2 {
  font-size: 32px;
  font-weight: 500;
  color: var(--sub);
  // text-align: center;
}
h3 {
  font-size: 28px;
}
h4 {
  font-size: 25px;
}
h5 {
  font-size: 20px;
}
.v-application p {
  margin-bottom: 0 !important;
}
a {
  text-decoration: none;
}

//ヘッダー用のスペースレンジャー
.space-top-70 {
  padding-top: 70px;
  @media screen and (max-width: 767px) {
    padding-top: 60px;
  }
}
//共通スペースレンジャー
.space-btm-200 {
  margin-bottom: 200px;
  @media screen and (max-width: 767px) {
    margin-bottom: 100px;
  }
}
.space-btm-200p {
  padding-bottom: 200px;
  @media screen and (max-width: 767px) {
    padding-bottom: 100px;
  }
}
.space-top-130 {
  margin-top: 130px;
  @media screen and (max-width: 767px) {
    margin-top: 60px;
  }
}
.space-top-150 {
  margin-top: 150px;
  @media screen and (max-width: 767px) {
    margin-top: 80px;
  }
}

</style>
