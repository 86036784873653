<template>
  <div class="layout-footer">
    <!-- <div class="tokuteisho" v-show="path !== `/tokuteisho/`">
      <router-link to="/tokuteisho/">特定商取引法に基づく表示</router-link>
    </div> -->
    <div class="footer" :class="{ 'footer-sp-bottom': path !== `/contact/` }">
      <div class="fotter-inner">
  <svg
            id="レイヤー_1"
            data-name="レイヤー 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="200 30 530 150"
            width="300px"
            height="70px"
          >
            <defs></defs>
            <path
              class="cls-1"
              d="M235.31,137.75c1.61,0,2.74,0,3.8-.13-.1,1.14-.17,2.12-.17,3v17.22a26.71,26.71,0,0,0,.21,3.63h-5.76v-1.32H219.14v1.32h-5.65a28.87,28.87,0,0,0,.2-3.63v-17.2a29.27,29.27,0,0,0-.17-3c1.13.08,2.37.13,3.94.13Zm-1.92,9.07v-5.14H219.14v5.14Zm-14.25,3.8v5.55h14.25v-5.55Z"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-1"
              d="M267.61,152.77c-.35,2.63-1,4.11-2.4,5.6s-3.22,2.49-6.2,3.55a11.24,11.24,0,0,0-3-3.37,14.07,14.07,0,0,0,2-.63,16.4,16.4,0,0,1-2.36-2,14.6,14.6,0,0,1-4.84,5.92,18,18,0,0,0-3.42-3.21,10.37,10.37,0,0,0,3.94-4.94c.92-2.38,1.27-5.73,1.27-12.1a19.6,19.6,0,0,0-.28-4.56h5.35a28.85,28.85,0,0,0-.17,4.8,6.51,6.51,0,0,0,.82-1.14l1.71,1.27v-3.39c1.13.1,2.09.16,3.6.16h2.87v-.45a6.42,6.42,0,0,0-.13-1.44h5.24a6.11,6.11,0,0,0-.14,1.49v.4h3.94c1.47,0,2.54-.06,3.6-.16v3.79c-1.06-.08-2.3-.13-3.39-.13h-4.35v.27a8.11,8.11,0,0,0,.06,1.38h-4.69a7.22,7.22,0,0,0,.07-1.33v-.32h-3.08c-1,0-2,0-3.12.11l.86.63h4.45a3.22,3.22,0,0,0-.07.88v.64h6.47v-.59a4.59,4.59,0,0,0-.06-.93H277a2.49,2.49,0,0,0-.14.93v.59h.41a21.24,21.24,0,0,0,2.77-.14v3.75c-.89-.08-1.61-.11-2.77-.11h-.41v1.33c1.16,0,1.78,0,2.71-.11v3.77c-1.07-.11-2.37-.16-3.91-.16h-1.64v4.64c0,.35.17.43.92.43s.82-.11.93-.43a14.85,14.85,0,0,0,.17-2.76,20.91,20.91,0,0,0,4.35,1.28c-.24,4.93-.93,5.54-6.17,5.54-4,0-5.07-.5-5.07-2.36v-6.34Zm-20-3.85a23.67,23.67,0,0,0,.78-7.08l3.39.26a34.08,34.08,0,0,1-.85,8.23Zm11.26-3.66c-.27.21-.47.4-.61.5a9.05,9.05,0,0,1-.86.75,38.2,38.2,0,0,1-.51,5.12,16.38,16.38,0,0,0,3.7,2.65,14,14,0,0,0-2.4,3.58c2.81-1.16,4.18-2.73,4.45-5.09a28.73,28.73,0,0,0-3.56.13v-3.71c.72.05,1.1.08,2.23.08v-1.33a23.42,23.42,0,0,0-2.44.11Zm2.44-1.38a3.87,3.87,0,0,0-.07-.69l-1.37,1.22c.55,0,.75,0,1.44,0Zm4.45,5.39h6.47v-1.33h-6.47Z"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-1"
              d="M295.55,138.2a34.28,34.28,0,0,0-1.81,7.54,43,43,0,0,0-.35,6.1s0,.24,0,1a16.64,16.64,0,0,1,1.75-3.26l4,1.54c-2.22,2.78-4.14,6.79-4.35,9.05l-5.51.15c-.11-.55-.11-.55-.48-2.09a36.91,36.91,0,0,1-1-8.41c0-2.92.14-4.17,1.16-9.88a10.53,10.53,0,0,0,.21-2.09Zm1.48,4.62a44.52,44.52,0,0,0,6.16.37c1.27,0,2.12,0,3.12-.11-.24-4.51-.24-4.56-.52-5.52l6.1-.05a6.84,6.84,0,0,0-.1,1,3.71,3.71,0,0,0,0,.5c0,.51.07,1.75.14,3.67a25.2,25.2,0,0,0,4.8-.91l.3,4.43c-.58.06-.78.08-2,.24a27,27,0,0,1-2.95.35c0,1.27,0,1.54,0,2.15,0,3.39-.69,6.07-2,7.85-1.5,2-3.22,3.29-6.71,4.86a20.61,20.61,0,0,0-4.69-3.53c3.56-1.2,5.82-2.79,6.81-4.7.66-1.27.9-2.84.9-5.94v-.32c-1.34.08-2.2.11-3.67.11-1.3,0-2.4,0-3.6-.08s-1.13-.06-2.19-.06Z"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-1"
              d="M343,142.45V140.7a14.9,14.9,0,0,0-.18-3H349c-.14.8-.14.8-.17,4.73h1.33a38.67,38.67,0,0,0,4.94-.19v4.54c-1.65-.11-2.61-.13-4.83-.13h-1.51a30.67,30.67,0,0,1-.24,3.68,10.69,10.69,0,0,1-5.07,8,23.27,23.27,0,0,1-7.09,3,13.54,13.54,0,0,0-3.73-4.06,20.72,20.72,0,0,0,6.61-2.34c2.33-1.43,3.32-3.16,3.59-6.13,0-.48,0-.66.07-2.17h-7.22v3a19.07,19.07,0,0,0,.13,2.73h-6.27a15,15,0,0,0,.21-2.79v-2.91h-1.27c-2.47,0-2.91,0-4.83.13v-4.54a38.49,38.49,0,0,0,4.93.19h1.17v-1.94a13.93,13.93,0,0,0-.21-2.81h6.27a15.56,15.56,0,0,0-.13,2.7v2Z"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-1"
              d="M369.7,158.45v1.83h-6.23a27,27,0,0,0,.27-4.33V142.31a30.46,30.46,0,0,0-.13-3.05c1.19.08,1.88.11,4.14.11h18.46c2.13,0,2.64,0,4.25-.11-.07.93-.1,1.89-.1,3.13v13.46a28.06,28.06,0,0,0,.24,4.38h-6.2v-1.78Zm14.7-14.84H369.7V154.2h14.7Z"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-1"
              d="M404.6,138.73a51.59,51.59,0,0,1,9.42,5.2l-4,4.14a37.26,37.26,0,0,0-9.32-5.6ZM401,155.21a43,43,0,0,0,10.31-1.91,20.11,20.11,0,0,0,10-6.56,22.39,22.39,0,0,0,3.36-5.2,21,21,0,0,0,5,3.56c-3.12,5.23-6.37,8.36-11.17,10.75-3.7,1.85-6.95,2.84-13.33,4-1.33.24-1.54.26-2.46.47Z"
              transform="translate(-1.86 0.03)"
            />
            <line
              class="cls-2"
              x1="286.16"
              y1="125.86"
              x2="469.47"
              y2="125.86"
            />
            <line
              class="cls-2"
              x1="171.19"
              y1="125.86"
              x2="241.94"
              y2="125.86"
            />
            <path
              class="cls-3"
              d="M264.76,78.71s-4.26-5.51-1.52-9.62a6.27,6.27,0,0,0-.08-7.47"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-4"
              d="M269.4,79.37s-4.11-5.48-2.74-9.59c1.56-4.69-2-8.41-3.5-8.16,0,0,.76,4.05-2,6.79-2.41,2.41,0,11,0,11Z"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-3"
              d="M253.89,83.05s-6.8-1.48-7.35-6.39A6.27,6.27,0,0,0,241.68,71"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-4"
              d="M257.87,80.57S251.2,79,249.61,75c-1.82-4.6-6.9-5.19-7.93-4,0,0,3.19,2.61,2.85,6.47-.3,3.4,7,8.4,7,8.4Z"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-3"
              d="M248.36,93.37s-6.17,3.24-9.74-.18A6.28,6.28,0,0,0,231.25,92"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-4"
              d="M249.81,88.91s-6.11,3.09-9.92,1c-4.34-2.35-8.62.46-8.64,2,0,0,4.12,0,6.34,3.13,2,2.8,10.8,1.9,10.8,1.9Z"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-3"
              d="M250.75,104.83s-2.64,6.44-7.57,6.12a6.27,6.27,0,0,0-6.43,3.8"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-4"
              d="M249,100.47s-2.69,6.3-6.94,7.17c-4.84,1-6.31,5.89-5.31,7.11,0,0,3.12-2.68,6.86-1.68,3.3.89,9.5-5.48,9.5-5.48Z"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-3"
              d="M260,112.06s2.12,6.64-1.87,9.56a6.28,6.28,0,0,0-2.48,7"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-4"
              d="M255.81,109.85s2,6.56-.71,10c-3.07,3.86-1,8.56.5,8.86,0,0,.67-4.06,4.18-5.7,3.09-1.45,3.75-10.3,3.75-10.3Z"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-3"
              d="M271.64,111.7s5.89,3.71,4.72,8.51a6.29,6.29,0,0,0,2.63,7"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-4"
              d="M267.05,112.67s5.74,3.74,5.86,8.07c.13,4.94,4.7,7.24,6.08,6.47,0,0-2.1-3.54-.46-7,1.44-3.1-3.75-10.31-3.75-10.31Z"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-3"
              d="M280.37,103.9s6.9-.94,9.09,3.49a6.28,6.28,0,0,0,6.51,3.67"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-4"
              d="M277.48,107.59s6.8-.82,9.67,2.42c3.28,3.7,8.26,2.52,8.82,1,0,0-3.89-1.36-4.89-5.11-.88-3.29-9.49-5.48-9.49-5.48Z"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-3"
              d="M282,92.31s4.68-5.15,9.21-3.16a6.28,6.28,0,0,0,7.34-1.38"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-4"
              d="M282.2,97s4.68-5,9-4.36c4.88.72,7.94-3.38,7.42-4.87,0,0-3.85,1.46-7-.77-2.8-2-10.8,1.91-10.8,1.91Z"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-3"
              d="M275.87,82.36s.28-7,5-8.34a6.27,6.27,0,0,0,4.74-5.77"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-4"
              d="M279,85.86s.37-6.84,4.07-9.11c4.2-2.59,3.91-7.69,2.55-8.5,0,0-2,3.59-5.87,3.93-3.4.29-7.05,8.39-7.05,8.39Z"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-5"
              d="M259.94,82.41s-5-3.16-4-7.24a5.33,5.33,0,0,0-2.24-5.94"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-6"
              d="M263.85,81.59s-4.88-3.19-5-6.87c-.11-4.19-4-6.14-5.17-5.49a7,7,0,0,1,.4,6c-1.23,2.63,3.18,8.76,3.18,8.76Z"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-5"
              d="M252.53,89s-5.86.79-7.72-3A5.35,5.35,0,0,0,239.27,83"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-6"
              d="M255,85.9s-5.78.7-8.22-2.06c-2.79-3.14-7-2.14-7.5-.89a7,7,0,0,1,4.16,4.34c.75,2.8,8.07,4.66,8.07,4.66Z"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-5"
              d="M251.11,98.88s-4,4.38-7.82,2.69a5.36,5.36,0,0,0-6.25,1.17"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-6"
              d="M251,94.9s-4,4.25-7.63,3.71c-4.15-.62-6.75,2.87-6.31,4.13a7,7,0,0,1,6,.66c2.37,1.66,9.17-1.62,9.17-1.62Z"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-5"
              d="M256.35,107.34s-.23,5.91-4.26,7.09a5.33,5.33,0,0,0-4,4.9"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-6"
              d="M253.69,104.37s-.32,5.81-3.46,7.74c-3.58,2.2-3.33,6.54-2.17,7.22a7,7,0,0,1,5-3.33c2.89-.26,6-7.14,6-7.14Z"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-5"
              d="M265.8,110.44s3.62,4.68,1.29,8.18a5.33,5.33,0,0,0,.07,6.35"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-6"
              d="M261.85,109.88s3.49,4.66,2.33,8.15c-1.33,4,1.65,7.15,3,6.94a7,7,0,0,1,1.68-5.77c2.05-2,0-9.32,0-9.32Z"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-5"
              d="M275,106.75s5.78,1.26,6.25,5.43a5.33,5.33,0,0,0,4.13,4.82"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-6"
              d="M271.65,108.86s5.67,1.32,7,4.75c1.54,3.9,5.86,4.41,6.74,3.39a7,7,0,0,1-2.42-5.49c.25-2.89-6-7.14-6-7.14Z"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-5"
              d="M279.73,98s5.24-2.76,8.28.14a5.33,5.33,0,0,0,6.26,1"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-6"
              d="M278.5,101.78s5.19-2.63,8.43-.88c3.69,2,7.32-.39,7.34-1.73a7,7,0,0,1-5.39-2.66c-1.66-2.37-9.17-1.61-9.17-1.61Z"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-5"
              d="M277.7,88.25s2.24-5.47,6.43-5.2a5.34,5.34,0,0,0,5.47-3.24"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-6"
              d="M279.19,92s2.29-5.35,5.89-6.09c4.12-.84,5.37-5,4.52-6a7,7,0,0,1-5.84,1.43c-2.8-.75-8.06,4.66-8.06,4.66Z"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-5"
              d="M269.89,82.1s-1.81-5.64,1.58-8.12a5.34,5.34,0,0,0,2.11-6"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-6"
              d="M273.4,84s-1.69-5.58.6-8.46c2.61-3.29.89-7.28-.42-7.53A7,7,0,0,1,270,72.83c-2.63,1.23-3.19,8.76-3.19,8.76Z"
              transform="translate(-1.86 0.03)"
            />
            <circle class="cls-7" cx="263.46" cy="95.9" r="19.38" />
            <path
              class="cls-1"
              d="M446.59,77.19a33.71,33.71,0,0,1,8.82,1.21,25.18,25.18,0,0,1,8,3.78,19.92,19.92,0,0,1,5.83,6.58,19.21,19.21,0,0,1,2.23,9.51,19.17,19.17,0,0,1-2.23,9.56,19.67,19.67,0,0,1-5.83,6.48,24.27,24.27,0,0,1-8,3.64,35.25,35.25,0,0,1-17.63,0,24.18,24.18,0,0,1-8-3.64,19.45,19.45,0,0,1-5.83-6.48,19.17,19.17,0,0,1-2.24-9.56,19.22,19.22,0,0,1,2.24-9.51,19.7,19.7,0,0,1,5.83-6.58,25.08,25.08,0,0,1,8-3.78A33.57,33.57,0,0,1,446.59,77.19Zm0,9.89a15.18,15.18,0,0,0-4.94.8,13.06,13.06,0,0,0-4.11,2.24,10.08,10.08,0,0,0-2.74,3.51,11.69,11.69,0,0,0,0,9.37,10.08,10.08,0,0,0,2.74,3.51,13.09,13.09,0,0,0,4.11,2.25,16,16,0,0,0,9.93,0,13.17,13.17,0,0,0,4.06-2.25,10.11,10.11,0,0,0,2.75-3.51,11.69,11.69,0,0,0,0-9.37,10.11,10.11,0,0,0-2.75-3.51,13.14,13.14,0,0,0-4.06-2.24A15.09,15.09,0,0,0,446.59,87.08Z"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-1"
              d="M316.77,95.4H303.43a2.1,2.1,0,0,0-1.12.37c-.37.25-.56.81-.56,1.68h-1.12V83.83h1.12c0,.88.19,1.43.56,1.68a2,2,0,0,0,1.12.38H342.6a2,2,0,0,0,1.12-.38c.37-.25.56-.8.56-1.68h1.12V97.45h-1.12c0-.87-.19-1.43-.56-1.68a2,2,0,0,0-1.12-.37H329.27"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-1"
              d="M316.77,83.42V76.35a1.24,1.24,0,0,0-.7-1.31,5.62,5.62,0,0,0-1.45-.37V73.55h16.79v1.12A5.45,5.45,0,0,0,330,75a1.24,1.24,0,0,0-.7,1.31v7.07"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-1"
              d="M228.08,117a1.4,1.4,0,0,0,.42,1.07,4.08,4.08,0,0,0,2.19.61v1.12H211.48v-1.12a4.15,4.15,0,0,0,2.19-.61,1.44,1.44,0,0,0,.42-1.07V99.72H189.84V117a1.44,1.44,0,0,0,.42,1.07,4.12,4.12,0,0,0,2.19.61v1.12H173.23v-1.12a4.13,4.13,0,0,0,2.2-.61,1.44,1.44,0,0,0,.42-1.07V73.52a1.48,1.48,0,0,0-.42-1.08,4.25,4.25,0,0,0-2.2-.6V70.72h19.22v1.12a4.24,4.24,0,0,0-2.19.6,1.48,1.48,0,0,0-.42,1.08V88.35h24.25V73.52a1.48,1.48,0,0,0-.42-1.08,4.28,4.28,0,0,0-2.19-.6V70.72h19.21v1.12a4.2,4.2,0,0,0-2.19.6,1.44,1.44,0,0,0-.42,1.08Z"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-1"
              d="M361,73.52a1.48,1.48,0,0,0-.42-1.08,4.29,4.29,0,0,0-2.2-.6V70.72h31a30.64,30.64,0,0,1,11.19,1.86,21.08,21.08,0,0,1,7.83,5.18A21.4,21.4,0,0,1,413,85.55a29.53,29.53,0,0,1,1.54,9.7,26.27,26.27,0,0,1-1.63,9.18,22.15,22.15,0,0,1-4.94,7.84,24.41,24.41,0,0,1-8.21,5.45,29.41,29.41,0,0,1-11.43,2.06H358.36v-1.12a4.16,4.16,0,0,0,2.2-.61A1.44,1.44,0,0,0,361,117Zm25.83,35.25q7.37,0,10.63-3.77a14.37,14.37,0,0,0,3.27-9.75,14.37,14.37,0,0,0-3.27-9.75q-3.27-3.78-10.63-3.77H375v27Z"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-1"
              d="M329.27,83.06v34.2a1.25,1.25,0,0,0,.7,1.31,5.86,5.86,0,0,0,1.44.37v1.12H314.62v-1.12a6.06,6.06,0,0,0,1.45-.37,1.25,1.25,0,0,0,.7-1.31V83.06"
              transform="translate(-1.86 0.03)"
            />
            <path
              class="cls-1"
              d="M514.8,77.32v1.14a5.68,5.68,0,0,1,1.47.38A1.19,1.19,0,0,1,517,80a26.67,26.67,0,0,0-4-1.46,33.21,33.21,0,0,0-17.91,0,25.57,25.57,0,0,0-8.15,3.84A20.2,20.2,0,0,0,481,89.07a19.51,19.51,0,0,0-2.28,9.67,19.42,19.42,0,0,0,2.28,9.71A19.85,19.85,0,0,0,486.9,115a24.47,24.47,0,0,0,8.15,3.7,35.74,35.74,0,0,0,17.91,0,26.94,26.94,0,0,0,4-1.38v7.25h0a2,2,0,0,1,0,.25h0a9,9,0,0,1-.6,1.65,10.37,10.37,0,0,1-2.8,3.57,13.43,13.43,0,0,1-4.12,2.28,16.12,16.12,0,0,1-10.09,0,13.35,13.35,0,0,1-4.17-2.28,10.24,10.24,0,0,1-2.29-2.63,8.82,8.82,0,0,1-1.29-5v0a1.18,1.18,0,0,1,.7-1.08,5.66,5.66,0,0,1,1.47-.37v-1.14H476.72V121a5.66,5.66,0,0,1,1.47.37c.48.19.71.63.71,1.33h0a22.4,22.4,0,0,0,.87,4.74,16.16,16.16,0,0,0,1.59,4,19.75,19.75,0,0,0,5.92,6.58,24.47,24.47,0,0,0,8.15,3.7,35.74,35.74,0,0,0,17.91,0,24.65,24.65,0,0,0,8.15-3.7,19.85,19.85,0,0,0,5.92-6.58,19.53,19.53,0,0,0,2.27-9.71V80.16a1.26,1.26,0,0,1,.71-1.32,5.79,5.79,0,0,1,1.47-.38V77.32Zm-1.61,29.79a13.43,13.43,0,0,1-4.12,2.28,16.12,16.12,0,0,1-10.09,0,13.35,13.35,0,0,1-4.17-2.28,10.22,10.22,0,0,1-2.79-3.57A11.83,11.83,0,0,1,492,94a10.19,10.19,0,0,1,2.79-3.56A13,13,0,0,1,499,88.18a15.93,15.93,0,0,1,10.09,0,13,13,0,0,1,4.12,2.28A10.21,10.21,0,0,1,516,94a11.83,11.83,0,0,1,0,9.52A10.25,10.25,0,0,1,513.19,107.11Z"
              transform="translate(-1.86 0.03)"
            />
            
                   
          </svg>
        <div class="copyright">
          <p>Copyright © HotDog All Rights Reserved.</p>
        </div>
      </div>
      <div class="sp-contact-overlay">
        <div class="mail-contact" v-scroll-to="'#reservation'">
          <span>ご予約・お問い合わせ</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LayoutFooter",
  components: {},
  props: {
    path: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.cls-1,
.cls-8 {
  fill: #231815;
}
.cls-10,
.cls-12,
.cls-13,
.cls-2,
.cls-3,
.cls-4 {
  fill: none;
}
.cls-12,
.cls-2 {
  stroke: #231815;
}
.cls-10,
.cls-12,
.cls-13,
.cls-2,
.cls-3,
.cls-4,
.cls-5,
.cls-6,
.cls-8,
.cls-9 {
  stroke-miterlimit: 10;
}
.cls-2 {
  stroke-width: 2.28px;
}
.cls-3,
.cls-4,
.cls-5,
.cls-6 {
  stroke: #ec6f00;
}
.cls-3 {
  stroke-width: 0.78px;
}
.cls-4 {
  stroke-width: 1.37px;
}
.cls-5,
.cls-6,
.cls-7 {
  fill: #ec6f00;
}
.cls-5 {
  stroke-width: 0.66px;
}
.cls-6 {
  stroke-width: 1.16px;
}

.tokuteisho {
  text-align: center;
  margin-bottom: 5px;
  a {
    text-decoration: none;
    color: black;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
.footer {
  .fotter-inner {
    background-image: url(~@/assets/hotdog-top-003.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 200px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 20px 40px;
    @media screen and (max-width: 767px) {
      align-items: center;
      flex-direction: column;
    }
  }
  .copyright {
    font-size: 13px;
    // color: white;
    // margin-right: 200px;
    @media screen and (max-width: 767px) {
      margin-right: 0;
    }
  }
  .sp-contact-overlay {
    display: none;
    @media screen and (max-width: 767px) {
      display: initial;
      display: flex;
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 56px;
      // background-color: rgba(255, 255, 255, 0.8);
      align-items: center;
      justify-content: center;
      z-index: 999;
      .mail-contact {
        width: 90%;
        padding: 8px 0;
        // border: 1px solid black;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--sub);
        opacity: 0.9;
        span {
          text-decoration: none;
          color: white;
          font-weight: 700;
          font-size: 18px;
        }
      }
    }
  }
}
.footer-sp-bottom {
  @media screen and (max-width: 767px) {
    margin-bottom: 50px;
  }
}
</style>
