<template>
  <div class="home-reason">
    <UiHeading :h="'2'" :type="'E2'" :text="'ご予約'" />

    <div class="cover">
      <UiHeading
        :h="'2'"
        :type="'B'"
        :text="'お電話での<br class=sp-only>ご予約・お問い合わせ'"
      />
      <p class="tel"><a href="tel:0529629626">052-962-9626</a></p>
    </div>

    <div class="cover">
      <UiHeading
        :h="'2'"
        :type="'B'"
        :text="'InstagramのDMからの<br class=sp-only>ご予約・お問い合わせ'"
      />
      <a
        href="https://www.instagram.com/tanning_studio_hotdog/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img alt="Instagram" src="@/assets/hotdog-top-insta-logo.jpg" />
      </a>
      <p>ID：tanning_studio_hotdog</p>
    </div>

    <div class="cover">
      <UiHeading
        :h="'2'"
        :type="'B'"
        :text="'メールでの<br class=sp-only>ご予約・お問い合わせ'"
      />
    </div>
<HomeReserveContactForm/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import HomeReserveContactForm from "@/components/Home/Reserve/ContactForm.vue";
export default {
  name: "HomeReason",
  components: {
    UiHeading,
    HomeReserveContactForm,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.cover {
  text-align: center;
  margin-top: 100px;
  .tel,img{
    margin-top: 50px;
    a{
      font-size: 50px;
      font-weight: bold;
      letter-spacing: 0.2em;
      color: var(--sub);  
      @media screen and (max-width: 767px) {
        font-size: 34px;
      } 
    }
  }
  img{
    width: 150px;
          display: inline-block;
      transition: 0.3s;
      -webkit-transform: scale(1);
      transform: scale(1);
      &:hover {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
  p{
    font-weight: bold;
    font-size: 20px;
  }
}
@media (min-width: 751px) {
a[href*="tel:"] {
pointer-events: none;
cursor: default;
text-decoration: none;
}
}
</style>