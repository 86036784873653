<template>
  <div class="home-machine-price">
    <div class="wrap">
      <UiHeading :h="'2'" :type="'E4'" :text="'マシンとご利用料金'" />
      <UiHeading
        :h="'3'"
        :type="'B2'"
        :text="'ブラック会員認定で<br class=sp-only>料金がさらに安く！'"
      />

      <div class="cover">
        <div class="left">
          <!-- <p>
            HotDogは、30回以上ご来店いただいたお客様をブラック会員に認定させていただき、ブラック会員価格にてご案内させていただきます。

          </p> -->
          <h4>ブラック会員割引き</h4>
          <p>
            スタンプカードを全て貯めていただいた方は、永年割引ブラック会員価格でご案内させていただきます。
          </p>
          <!-- <p>学生は、学生証をご提示することで500円オフとなります。</p> -->
          <h4>各種割引き</h4>
          <p>
            学割・年齢別割引きのご用意がありますので、お電話にてお問い合わせください。<br />※学割をご利用の際は学生証のご提示をお願いいたします。
          </p>
          <p style="margin: 1em 0">
            ※当サイト上の料金はすべて税込表示です。<br />
            ※クレジットカード・交通系・クイックペイで決済ができます。
          </p>
        </div>
        <div class="right">
          <h4>ご利用料金には、以下が含まれています。</h4>
          <p>
            オイル、シャワー、クールダウンオイル、タオル、保湿液、ドライヤー<br />※着替え部屋有り
          </p>
          <h4>業界最高クラスのマシンで安心して焼けます</h4>
          <p>
            当店の日焼けマシンは、高級ランクとして位置付けられ、世界中の日焼けサロンで愛用されています。業界では世界トップクラスのテクノロジーで数多くの特許を取得！世界レベルの日焼けマシンをあなた自身で感じてみてください。
          </p>
        </div>
      </div>

      <UiHeading :h="'3'" :type="'B2'" :text="'ボディ用マシン'" />
      <HomeMachineBody
        :title="'K-SUN SP'"
        :src="'hotdog-top-ma001.jpg'"
        :des="'下地作りから小麦肌向け<br>※はじめての方から中級者マシン'"
        :wattage="'8,500'"
        :tableList="TableList"
      />
      <HomeMachineBody
        :title="'S-45'"
        :src="'hotdog-top-ma003.jpg'"
        :des="'小麦肌を目指す方にオススメ<br>※中級マシン'"
        :wattage="'10,000'"
        :tableList="TableList3"
      />
      <HomeMachineBody
        :title="'Lotus SP'"
        :src="'hotdog-top-ma002.jpg'"
        :des="'黒肌作りにはこのマシン<br>※上級者マシン'"
        :wattage="'11,000'"
        :tableList="TableList2"
      />
      <HomeMachineBody
        :title="'iBed'"
        :src="'hotdog-top-ma004.jpg'"
        :des="'驚きの焼け感<br>※上級者マシン'"
        :note="'※店内のマシンとはカラーが異なります。'"
        :wattage="'15,000'"
        :tableList="TableList4"
        class="small-font"
      />
      <HomeMachineFace
        :title="'フェイスマシン'"
        :src="'hotdog-top-ma005.jpg'"
        :des="'日本に1台のモンスターマシン'"
        :note="'※店内のマシンとはカラーが異なります。'"
        :wattage="'5,000'"
        :tableList="TableList5"
        class="last"
      />
    </div>
    <div>
      <UiHeading
        :h="'3'"
        :type="'B7'"
        :text="'通常会員もブラック会員も<br class=sp-only>全身型とフェイスセットで半額'"
      />
      <div class="star">
        <p>
          はじめの数回は数日スパンで通っていただき、下地を作りましょう。<br />その後は、お好みのペース（1週間～2週間）でご来店いただくことで、お好みの焼け方を維持できます。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import HomeMachineBody from "@/components/Home/MachineBody.vue";
import HomeMachineFace from "@/components/Home/MachineFace.vue";
export default {
  name: "HomeMachinePrice",
  components: {
    UiHeading,
    HomeMachineBody,
    HomeMachineFace,
  },
  props: {},
  data() {
    return {
      TableList: [
        {
          id: 1,
          th: "20分",
          td: "2,420円",
          td2: "50分まで2,970円",
        },
        {
          id: 2,
          th: "30分",
          td: "2,970円",
          td2: "以降10分毎",
        },
        {
          id: 3,
          th: "40分",
          td: "3,520円",
          td2: "+440円",
        },
      ],
      TableList2: [
        {
          id: 1,
          th: "20分",
          td: "3,630円",
          td2: "30分まで2,970円",
        },
        {
          id: 2,
          th: "30分",
          td: "4,180円",
          td2: "以降10分毎",
        },
        {
          id: 3,
          th: "40分",
          td: "4,730円",
          td2: "+440円",
        },
        // {
        //   id: 4,
        //   th: "50分",
        //   td: "4,180円",
        //   td2: "+330",
        // },
      ],
      TableList3: [
        {
          id: 1,
          th: "20分",
          td: "2,970円",
          td2: "40分まで2,970円",
        },
        {
          id: 2,
          th: "30分",
          td: "3,520円",
          td2: "以降10分毎",
        },
        {
          id: 3,
          th: "40分",
          td: "4,070円",
          td2: "+440円",
        },
        // {
        //   id: 4,
        //   th: "50分",
        //   td: "4,180円",
        //   td2: "+330",
        // },
        // {
        //   id: 5,
        //   th: "60分",
        //   td: "4,620円",
        //   td2: "+660",
        // },
      ],
      TableList4: [
        {
          id: 1,
          th: "20分",
          td: "3,630円",
          td2: "30分まで2,970円",
        },
        {
          id: 2,
          th: "30分",
          td: "4,180円",
          td2: "以降10分毎",
        },
        {
          id: 3,
          th: "40分",
          td: "4,730円",
          td2: "+440円",
        },
        // {
        //   id: 4,
        //   th: "40分",
        //   td: "4,950円",
        //   td2: "+1,100",
        // },
      ],
      TableList5: [
        {
          id: 1,
          th: "10分",
          td: "1,540円",
        },
        {
          id: 2,
          th: "15分",
          td: "2,090円",
        },
        {
          id: 3,
          th: "20分",
          td: "2,640円",
        },
        {
          id: 4,
          th: "25分",
          td: "3,190円",
        },
        {
          id: 4,
          th: "30分",
          td: "3,300円",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
h3 {
  font-weight: bold !important;
}
.cover {
  color: white !important;
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 100px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  .left,
  .right {
    width: 48%;
    p:nth-of-type(1) {
      padding-bottom: 10%;
    }
    h4 {
      color: var(--sub);
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .right {
    h4:nth-of-type(1) {
      @media screen and (max-width: 767px) {
        margin-top: 10%;
      }
    }
  }
}
.last {
  border-bottom: none !important;
}
.star {
  p {
    color: white;
    text-align: center;
    padding: 50px 0 200px;
    font-size: 20px;
    line-height: 2em;
    @media screen and (max-width: 767px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
::v-deep.small-font {
  .des {
    @media screen and (max-width: 767px) {
      font-size: 14px !important;
    }
  }
}
</style>